import { type IOptionCollectInfo } from './option-collect-info'

export const useOptionCollectDialogStore = defineStore(
  'optionCollectDialog',
  () => {
    const DEFAULT_DATA: IOptionCollectInfo = {
      type: 'BUY',
      sniperType: 'uSNIPER',
      address: '',
      strikePrice: '0',
      amount: '0',
      dobReward: {
        amount: '0',
        usd: '0',
      },
      formattedExerciseTimestamp: (Date.now() / 1000).toString(),
    }
    const {
      value: isDialogVisible,
      setTrue: openDialog,
      setFalse: closeDialog,
    } = useBoolean(false)
    const data = ref<IOptionCollectInfo>(DEFAULT_DATA)

    const setData = (newData: IOptionCollectInfo) => {
      data.value = newData
    }

    const reset = () => {
      data.value = DEFAULT_DATA
    }

    return {
      data,
      isDialogVisible,
      openDialog,
      closeDialog,
      setData,
      reset,
    }
  },
)
